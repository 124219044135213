
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  computed,
  createVNode,
  onActivated,
  onDeactivated,
  watchEffect,
} from 'vue';
import {
  PlusOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  SettingOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  RedoOutlined,
} from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import {query_question_list} from "@/api/baseinfo-model";
import { useRoute } from 'vue-router';
import { debounce } from 'lodash';
export default defineComponent({
  name: 'KeKuQuestion',
  props: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const elRef:any = ref();
    const cardHeight = ref(window.innerHeight - 200);
    const onresize:any = debounce(()=>{
      cardHeight.value = window.innerHeight - 200;
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });
    route.meta.title = route.params['name'].slice(0,7);
    watchEffect(() => {
      if (route.path.split('/')[2] == 'kefu_question') {
        route.meta.title =
          route.params['name'].slice(0,7);
      }
    });
    const current_org = store.getters['user/current_org'];
    const question_id = Number(route.params['id']);
    const question_obj = reactive({});
    query_question_list({
      org_id: current_org?.id,
      ids: question_id,
      is_active: true,
    }).then((res: any) => {
      if(res && res.data && res.data.length > 0){
        Object.assign(question_obj, res.data[0]);
      }
    })
    return {
      t,
      question_obj,
      cardHeight,
      elRef,
    };
  },
  components: {
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ExclamationCircleOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
  },
});
